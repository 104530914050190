.main_logo {
    border-bottom: 1px dashed grey;
}

.main_logo img {
    width: 300px;
    margin: 1%;
}

.bg-body-tertiary img {
    display: none;
}

.bg-body-tertiary .navitems {
    text-decoration: none;
    width: 140px;
    display: block;
    color: #a92525;
    text-align: center;
    justify-content: center;
    text-transform: uppercase;
    margin-top: 1%;
    margin-bottom: 1%;
    font-weight: bold;

}

.bg-body-tertiary .navitems:after {
    content: '';
    width: 0px;
    height: 2px;
    display: block;
    background: #e7bb2a;
    transition: 300ms;
}

.bg-body-tertiary .navitems:hover:after {
    width: 50%;
    margin-top: 0%;
    margin-left: 25%;
    align-items: center;
    color: #a92525;
}

.bg-body-tertiary .navitems:hover {
    color: #a92525;
}

.bg-body-tertiary .dropdown {
    background-color: white;
    color: #a92525;
    border: none;
    margin-top: 1%;
    outline: none;
    width: 140px;
    font-weight: bold;
    margin-bottom: 1%;
    
}

.bg-body-tertiary .dropmenu::after {
    content: '';
    width: 0px;
    height: 2px;
    display: block;
    background: #e7bb2a;
    transition: 300ms;

}

.bg-body-tertiary .dropmenu:hover:after {
    width: 40%;
    margin-top: -3%;
    margin-left: 25%;
    align-items: center;
}

#Submenu{
    background-color:#e7bb2a;
    width: fit-content;   
    margin-left: -70px;
}

#Submenu .galleryitemed{
    background-color: #e7bb2a;
    transition: all 0.5s ease-in-out;
    text-transform: uppercase;
    font-weight: bold;
    color: #a92525;
}
#Submenu .galleryitemed:hover{
    color: #a92525;
    margin-left: 3.8%;
    width: 70%;
}


@media screen and (max-width:899px) {
    .main_logo img {
        display: none;
    }

    .bg-body-tertiary img {
        display: flex;
        height: 60px;
    }

    #navbarDisplay .bg-body-tertiary {
        display: block;
        
    }
 
    .bg-body-tertiary .navitems{
        text-align: left;
        margin-left: 4%;
    }
  
   
    #navbarDisplay .bg-body-tertiary #responsive-navbar-nav{
        background-color: #e7bb2a;   
    }
    
    #navbarDisplay .bg-body-tertiary .dropmenu .dropdown{
        background-color: #e7bb2a; 
        left: -10%;
      
    }
   

    #navbarDisplay .bg-body-tertiary .dropmenu{
        background-color: #e7bb2a;   
       
    }

   #Submenu{
        background-color:#e7bb2a;
        width: fit-content;
        margin-left: 10%;
        
    }
    #Submenu .galleryitemed{
        color: #a92525;
        left: 200px;
       
    }

    .displayOnPc .bg-body-tertiary {
        display: none;
    }


}

@media screen and (min-width:1099px) {
    #navbarDisplay {
        display: none;
    }
}