/* Home page Video Styles */
.videoview {
  width: 100%;
  margin-top: -5%;
  height: 100%;
}



video {
  width: 100%;
  height: 100%;
  object-fit:cover;
  position:static;
  border-radius: 5px 5px 5px 5px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

}

.content {
  position:absolute;
  width: 100%;
  height: 100%;
  top: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}

.bg-text {
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  border: 3px solid #f1f1f1;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 40%;
  padding: 20px;
  text-align: center;
}

/* .overlay {
  position:absolute;
  top: 180px;
  left: 0;
  width: 100%;
  height: 95%;
   background-color: rgba(0,0,0,.4); 
   /* background-color: #000000cc;   */
  /* }  */

 @media screen and (max-width:899px) {
  .bg-text {
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
    color: white;
    font-weight: 400;
    border: 2px solid #f1f1f1;
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 80%;
    padding: 30px;
    text-align: center;
  }
  .videoview {
    width: 100%;
    margin-top: -12%;
    height: 100%;
    position: absolute;
  }
  /* .overlay {
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
     background-color: rgba(0,0,0,.4); 
     
   }   */
 }


/* home page doctor content styles */

.doctorcontent{
  background-image: url("../public/bagroundimage1.jpg");
 /* Add the blur effect */
 /* filter: blur(8px); */
 /* -webkit-filter: blur(0.5px); */
 
 /* Full height */
 height: 100%; 
 
 /* Center and scale the image nicely */
 background-position:center;
 background-repeat: no-repeat;
 background-size: cover;
 margin-top: -1%;


}
.doctorcontent .layer{
  color: #f1f1f1;
  background-color: rgba(0,0,0,0.4);
}
.doctorcontent .layer .details{
  margin-top: 10%;
}
.doctorcontent .layer .details1{
  margin-top: 10%;
  justify-content: center;
align-items: center;
text-align: center;
}
.doctorcontent .layer .details1 img{
  width: 70%;
  
}


@media (max-width:799px) {
  .doctorcontent .layer .details1 img{
    width: 100%;
  }
  .doctorcontent .layer .details{
    margin-top: 40%;
  }
}

.doctorcontent .layer .details1 img{
  height: 90%;
  margin-left: auto;
  margin-right: auto;
  /* border-radius: 5px 5px 5px 5px;
  border:  2px solid black; */
  
}

.doctorcontent .layer .details .name{
  color: #a92525;
  text-shadow: 2px 2px white;
  font-size: 50px;
  font-weight: bold;
}
.doctorcontent .layer .details p{
  font-size: 18px;
}

@media  screen and (max-width:899px) {
  .doctorcontent{
    margin-top: 170%;
  }
  #button .btn{
   margin-left: 38%;
    height: 50px;
  }

}

/* AREAS OF EXPERTISE blog */


.videoview1 {
  width: 100%;
  margin-top: 0%;
  height: 10%;
}

.video {
  width: 100%;
  height: 100%;
  object-fit:cover;
  position:static;
  border-radius: 5px 5px 5px 5px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;

}
.bg-text1 {
  background-color: rgb(0,0,0); /* Fallback color */
  background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
  color: white;
  font-weight: bold;
  border: 3px solid #f1f1f1;
  position: absolute;
  top: 280%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 100%;
  padding: 50+px;
  text-align: center;
}

@media screen and (max-width:599px) {
  .bg-text1 {
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0, 0.4); /* Black w/opacity/see-through */
    color: white;
    font-weight: 400;
    border: 0px solid #f1f1f1;
    position: absolute;
    top: 48%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    width: 60%;
    padding: 0px;
    text-align: center;
  }
  .videoview1 {
    width: 100%;
    margin-top: 0%;
    height: 50%;
    position: absolute;
  }
  /* .overlay {
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
     background-color: rgba(0,0,0,.4); 
     
   }   */
 }


 /* Footer styles */

 .Footer{
  margin-top: 3%;
  border-top: 1px solid #e7bb2a;
 }
 .Footer .container{
  padding: 2%;
  align-items: center;
  text-align: center;
  justify-content: center;
 }

 /* //services Styles */

 .Service_Banner h1{
  padding : 10% 0% 10% 3%;
  color: #a92525;
  text-transform: uppercase;
 }
 .Service_Banner{
  margin-top: -5%;
  background-color: rgb(243, 238, 233);
 }
 .service_Content{
  margin-top: 5%;
 }
 .service_Content h3{
  color: #c79902;
  font-weight: bold;
 }
 .service_Content p{
  text-align: justify;
 }
 .Service_Image{
  border: 2px dashed #a92525;
  padding: 5%;
  margin-top: 5%;
  margin-bottom: 5%;
  border-radius:5px 5px 5px 5px;
 }
 .Service_Image img{
  width: 100%;
  border-radius: 5px 5px 5px 5px;
 }

 @media (max-width:799px) {
   .Service_Banner{
    margin-top: 30%;
   }
 }
 /* Home Services Styles */

 .Services_Home h2{
  margin-top: 2%;
  margin-bottom: 2%;
  text-transform: uppercase;
  
 }
 .Services_Home .underline{
  margin-bottom: 2%;
  border-bottom: 1px solid grey;
 }
 .Services_Home .card{
    border-radius: 5px 5px 5px 5px;
    margin-top: 2%;
    margin-bottom: 2%;
 }
 .Services_Home img{
  border-radius: 5px 5px 5px 5px;
 }
 .Services_Home .card .vertical_Hedding{
  writing-mode: vertical-lr;
  text-transform: capitalize;
  -webkit-transform: rotate(180deg);
  padding: 10% 0 10% 0;
  color: #257ba9;
  

 }
 .Services_Home .card .Sub_Row .col-md-3{
  display: flex;
 }
 .Services_Home .card .Sub_Row .underline1{
  border-right: 1px dashed #e7bb2a;
  height: 50%;
 }
 .Services_Home .card .Sub_Row .Home_Service_Link{
  text-decoration: none;
  font-size: xx-large;
  color: #a92525;
  margin-right: 5px;
  margin-left: 80%;
  transition: all 0.5s ease-in-out;
  position: block;
 }
 .Services_Home .card .Sub_Row .Home_Service_Link:hover{
  margin-left: 85%;
 }
 .Services_Home .card .Sub_Row .col-md-9 p{
   margin-bottom: -12%;
 }

 .Services_Home .card{
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
 }

 @media (max-width:799px) {
  .Services_Home .card .Sub_Row{
    display: flex;
  }
  .Services_Home h2{
    margin-top: 125%;
  }
 }
/* 
 Doctor about us styles */

 .Doctor_image1 img{
  width: 70%;
  height: 70%;
 }

 /* blogs styles */

 .BlogsTitle .container h3{
   padding: 2%;
 }

 .BlogsTitle .container a{
  padding-top: 5%;
  padding-left: 2%;
  color: #a92525;
  font-size: x-large;
}
.BlogsTitle .container{
  padding: 5%;
  color: #a92525;
}

.BlogsContent h1{
  color: #a92525;
}
.BlogsContent h2{
  color: #e7bb2a;
}
.BlogsContent .blogImage img{
 width: 100%;
}








